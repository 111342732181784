<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('ledger_detail_jbxx', { id: $route.query.id })">基本信息</span>
      <span @click="toUrl('ledger_detail_jsxx', { id: $route.query.id })">结算信息</span>
      <span @click="toUrl('ledger_detail_glqd', { id: $route.query.id })">关联渠道</span>
      <span @click="toUrl('ledger_detail_dzzb', { id: $route.query.id })"  id="fzcolor">电子账簿</span>
      <span @click="toUrl('ledger_detail_cpxx', { id: $route.query.id })">产品信息</span>
      <span @click="toUrl('ledger_detail_bccl', { id: $route.query.id })">补充材料</span>
    </div>
    <div>
      <div>
          <div class="desc">
            <h5 class="jsxx">电子账簿信息</h5>
          </div>
          <div class="tab1">
            <table class="tab">
              <tr class="tab_title">
                <td style="padding-left: 10px">
                  <p>户名</p>
                </td>
                <td>
                  <p>账号</p>
                </td>
                <td>
                  <p>银行简称</p>
                </td>
                <td>
                  <p>银行全称</p>
                </td>
                <td>
                  <p>联行号</p>
                </td>
                <td>
                  <p>开户地</p>
                </td>
              </tr>
              <tr v-if="AccBooChannel.accountName != null && AccBooChannel.accountName != ''">
                <td style="padding-left: 10px">
                  <p>{{ AccBooChannel.accountName }}</p>
                </td>
                <td>
                  <p>{{ AccBooChannel.accountNo }}</p>
                </td>
                <td>
                  <p>{{ AccBooChannel.accountBankName }}</p>
                </td>
                <td>
                  <p>{{ AccBooChannel.accountBranchBankName }}</p>
                </td>
                <td>
                  <p>{{ AccBooChannel.bankBrnNo }}</p>
                </td>
                <td>
                  <p>{{ AccBooChannel.accountOpen }}</p>
                </td>
              </tr>
            </table>
          </div>
          <div class="desc">
            <h5 class="jsxx">出金账户信息</h5>
          </div>
          <div class="tab1">
            <table class="tab">
              <tr class="tab_title">
                <td style="padding-left: 10px">
                  <p>户名</p>
                </td>
                <td>
                  <p>收款账号</p>
                </td>
                <td>
                  <p>收款银行名称</p>
                </td>
                <td>
                  <p>联行号</p>
                </td>
              </tr>
              <tr v-if="AccBooChannel.accountName != null && AccBooChannel.accountName != ''">
                <td style="padding-left: 10px">
                  <p>{{ AccBooChannel.accountName }}</p>
                </td>
                <td>
                  <p>{{ AccBooChannel.receiveAccountNo }}</p>
                </td>
                <td>
                  <p>{{ AccBooChannel.receiveAccountBankName }}</p>
                </td>
                <td>
                  <p>{{ AccBooChannel.receiveAccountBankBrnNo }}</p>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn">
            <el-button @click="back" class="back">返回</el-button>
          </div>
      </div>     
    </div>
  </div>
</template>
<script>
import { getAccountBookChannelDetail} from "@/api/trading/electron.js";

export default {
  data() {
    return {
      AccBooChannel:{},
      mchId:"",
      infoId:"",
    };
  },
  created() {
    console.log(this.$route.query,"ppppppppppp")
    this.infoId = this.$route.query.infoId;
    this.getAccountBookDetail(this.infoId);  
  },
  methods: {
    //获取电子账簿渠道信息
    getAccountBookDetail(infoId){
      getAccountBookChannelDetail(infoId).then((res)=>{
        if(res){
          if(res.resultData.mchChannel!=null){
            this.AccBooChannel = res.resultData.mchChannel;
          }else{
            this.AccBooChannel = {};
          }
        }       
      })
    },
    // 返回
    back() {
      this.$router.go(-1);
    },
    // 页面跳转
    toUrl(name,query) {
      this.$router.push({
        name: name,
        query: {
          ...query,
        },
      });
    },
  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  background: var(--themeColor);
  border-color: var(--themeColor);
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: var(--title);
}
.back:hover {
  background: var(--themeColor);
  border-color: var(--themeColor);
  color: #fff;
}

.btn {
  width: 100%;
  text-align: center;
  height: 33px;
  margin-top: 50px;
  margin-bottom: 18px;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
  text-align: left;
}

.overAccount_ul_title i {
  color: var(--themeColor);
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}
</style>
